import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { StaticImage } from 'gatsby-plugin-image'
import Divider from '../components/Divider'

import PrivacyIcon from '../assets/Industries/Icons/privacy.svg'
import SecurityIcon from '../assets/Industries/Icons/security.svg'
import UsabilityIcon from '../assets/Industries/Icons/usability.svg'

import CTABox from '../components/CTABox'
import Section from '../components/Section'
import SectionTitleBlock from '../components/SectionTitleBlock'
import CTALink from '../components/CTALink'
import routes from '../routes'
import ContactUs from '../components/ContactUs'

const HeroImage = '../assets/Industries/Images/industries_header2.png'
const IndustriesImg = '../assets/Industries/Images/industries_grid.png'
const useStyles = makeStyles((theme) => ({
  /**
   * || Reusable components
   * ======================================= */
  sectionTitle: {
    marginBottom: 15,
    maxWidth: 280,

    textAlign: 'center',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 320,
    },

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 675,
    },
  },
  sectionDescription: {
    maxWidth: 350,

    color: 'rgba(115, 115, 115, 1)',

    textAlign: 'center',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 500,
    },

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 600,
    },
  },

  /**
   * || Hero Section
   * ======================================= */
  heroWrapper: {
    position: 'relative',
    top: -48, // Account for the header bar height
    width: '100%',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    // paddingBottom: 100,
    [theme.breakpoints.up('desktop')]: {
      // paddingBottom: 100,
    },
    [theme.breakpoints.up('desktopXL')]: {
      // paddingBottom: 250,
    },
  },
  hero: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 140,
    marginBottom: 100,
    overflow: 'hidden',
    width: 1600,

    [theme.breakpoints.up('tablet')]: {
      paddingTop: 156,
      justifyContent: 'left',
    },

    [theme.breakpoints.up('desktop')]: {
      top: 40,
    },

    // '& .gatsby-image-wrapper': {
    //   display: 'none !important',

    //   [theme.breakpoints.up('tablet')]: {
    //     display: 'block !important',
    //     height: 'auto',
    //     position: 'relative',
    //     width: '40%',
    //     maxWidth: '230px',
    //   },

    //   [theme.breakpoints.up('desktop')]: {
    //     maxWidth: '300px',
    //   },

    //   [theme.breakpoints.up('desktopXL')]: {},
    // },
  },
  heroImage: {
    display: 'none !important',

    // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    // borderRadius: 10,
    [theme.breakpoints.up('tablet')]: {
      // top: '60%', // 'calc(82% - 80px)',
      display: 'block !important',
      position: 'absolute !important',
      right: 'calc(-230px + 5%)',
      width: 'calc(200px + 45%)',
    },

    [theme.breakpoints.up('desktop')]: {
      width: 'calc(200px + 45%)',
      height: 'auto',
      marginRight: 50,
      right: 'calc(-275px + 5%)',
      top: '60%', // 'calc(82% - 80px)',

      transform: 'translate(0%, -50%)',
    },

    [theme.breakpoints.up('1100')]: {
      left: '50%',
      width: 800,
    },
    [theme.breakpoints.up('1400')]: {
      left: '47.5%',
      width: 900,
    },
  },
  heroContent: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.up('tablet')]: {
      padding: 28,
      width: '60%',
    },

    [theme.breakpoints.up('desktop')]: {},
  },
  heroTitle: {
    textAlign: 'left',
    fontSize: '32px',
    color: 'black',

    [theme.breakpoints.up('tablet')]: {
      fontSize: '40px',
      width: 400,
    },

    [theme.breakpoints.up('800')]: {
      fontSize: '44px',
      width: 414,
    },

    [theme.breakpoints.up('desktop')]: {
      fontSize: '56px',
      width: '100%',
    },
  },
  heroDescription: {
    maxWidth: 325,
    fontSize: 16,
    lineHeight: '160%',
    margin: '20px 0px',

    color: 'rgba(18, 17, 39, 0.56)',

    textAlign: 'left',
    whiteSpace: 'pre-line',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 270,
      fontSize: 16,
    },

    [theme.breakpoints.up('800')]: {
      maxWidth: 402,
      fontSize: 16,
    },

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 502,
      fontSize: 18,
      textAlign: 'left',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',

    [theme.breakpoints.up('tablet')]: {
      flexDirection: 'row',
      width: 'auto',
    },
  },
  signUpButton: {
    width: '90%',
    marginBottom: 10,
    fontSize: '16px',

    [theme.breakpoints.up('tablet')]: {
      marginRight: 10,
      fontSize: '12px',

      padding: 0,
      marginBottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '110px',
    },

    [theme.breakpoints.up('800')]: {
      width: '140px',
      fontSize: '14px',
    },

    [theme.breakpoints.up('desktop')]: {
      fontSize: '16px',
      width: '170px',
    },
  },
  /**
   * || CycurID Solution Section
   * ======================================= */
  cycuridSolutionSection: {
    marginTop: 50,
    // background: 'red',
    [theme.breakpoints.up(600)]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',

      maxWidth: theme.custom.maxWidth,
      margin: 'auto',
    },
    [theme.breakpoints.up('desktop')]: {
      marginTop: 100,
    },

    [theme.breakpoints.up('desktopXL')]: {
      marginTop: 150,
    },
  },
  cycuridSolutionImage: {
    display: 'none',
    [theme.breakpoints.up(600)]: {
      display: 'block',
      width: '100%',
      maxWidth: 450,
    },
    [theme.breakpoints.up('desktop')]: {
      width: '45%',
      maxWidth: 600,
    },
  },
  cycuridSolutionImage2: {
    marginTop: 40,
    marginBottom: -50,
    width: '90vw',
    [theme.breakpoints.up(600)]: {
      display: 'none',
    },
  },
  cycuridSolutionCard: {
    padding: '40px 30px',
    minWidth: 343,
    maxWidth: 500,
    marginTop: 15,
    marginBottom: 40,
    // background: '#F0F4F8',
    boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.14)',
    borderRadius: 5,

    [theme.breakpoints.up('tablet')]: {
      padding: '45px 35px',
      maxWidth: 412,
    },
    [theme.breakpoints.up('desktop')]: {
      marginTop: 30,
      maxWidth: 500,
      padding: '55px 40px',
    },

    '& p': {
      marginTop: 15,
      color: '#737373',
      whiteSpace: 'pre-line',
      [theme.breakpoints.up('desktopXL')]: {
        fontSize: '16px',
      },
    },
  },

  /**
   * || Fundamental Issues Section
   * ======================================= */
  fundamentalIssuesSection: {
    marginTop: -50,
    textAlign: 'center',
    // background: 'blue',
    [theme.breakpoints.up('tablet')]: {
      marginTop: -40,
      marginBottom: 130,
      maxWidth: theme.custom.maxWidth,
      margin: 'auto',
    },

    [theme.breakpoints.up('desktop')]: {
      marginTop: -20,
    },

    [theme.breakpoints.up('desktopXL')]: {
      marginTop: -70,
    },
  },
  fundamentalIssuesHead: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.up('desktop')]: {
      width: '100%',
      // margin: '20px 0px',
      padding: '100px 0px',
      background:
        'linear-gradient(179.63deg, #192B3E 61.31%, rgba(0, 0, 0, 0.28) 206.5%)',
      color: '#FFFFFF',
    },

    [theme.breakpoints.up('desktopXL')]: {
      margin: '50px 0px',
    },
  },
  fundamentalIssuesText: {
    width: 285,
    [theme.breakpoints.up('tablet')]: {
      width: 412,
    },
    [theme.breakpoints.up('desktop')]: {
      width: 620,
      fontSize: '16px',
      lineHeight: '128.6%',
      color: '#FFFFFF',
    },
  },
  fundamentalIssuesFlexContainer: {
    marginTop: 50,

    [theme.breakpoints.up('800')]: {
      display: 'flex',
      justifyContent: 'space-evenly',

      width: '100%',
    },
  },
  fundamentalIssuesItem: {
    marginTop: 40,
    maxWidth: 250,

    [theme.breakpoints.up('tablet')]: {
      // maxWidth: 300,
      marginTop: 25,
    },
    [theme.breakpoints.up('desktopXL')]: {
      maxWidth: 350,
    },

    '& svg': {
      width: 35,
      height: 'auto',

      color: '#2FA43B',
    },

    '& h3': {
      marginTop: 5,
      marginBottom: 10,
    },

    '& p': {
      color: '#737373',

      whiteSpace: 'pre-line',
    },
  },

  /**
   * || CycurID Text Block Section
   * ======================================= */
  titleSection: {
    margin: '10px 0px',
    padding: '50px 45px',
    color: '#FFFFFF',
    textAlign: 'center',
    background:
      'linear-gradient(179.63deg, #192B3E 61.31%, rgba(0, 0, 0, 0.28) 206.5%)',

    [theme.breakpoints.up('tablet')]: {
      margin: '120px 0px',
      padding: '45px 0px',
    },

    [theme.breakpoints.up('desktop')]: {
      margin: '120px 0px',
      padding: 55,
    },

    [theme.breakpoints.up('desktopXL')]: {
      margin: '180px auto',
      maxWidth: theme.custom.maxWidth,
    },

    '& h2': {
      marginTop: '20px',
      maxWidth: 350,

      [theme.breakpoints.up('desktop')]: {
        maxWidth: 650,
        fontSize: '32px',
      },

      [theme.breakpoints.up('desktopXL')]: {
        maxWidth: 850,
      },
    },
  },
  titleSectionText: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 600,
    marginTop: 10,
    [theme.breakpoints.up('tablet')]: {
      marginTop: 10,
      fontSize: '14px',
    },
    [theme.breakpoints.up('desktop')]: {
      marginTop: 15,
      fontSize: '25px',
      lineHeight: '37.5px',
    },
  },
  ctaBox: {
    marginTop: 50,
    marginBottom: 50,
    boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.14)',
    [theme.breakpoints.up('tablet')]: {
      marginTop: '120px !important',
      marginBottom: '120px !important',
    },
    [theme.breakpoints.up('desktop')]: {
      marginTop: '190px !important',
      marginBottom: '190px !important',
    },
  },
  // ctaBoxText: {
  //   [theme.breakpoints.up('tablet')]: {
  //     fontSize: '18px',
  //   },
  //   [theme.breakpoints.up('desktop')]: {
  //     fontSize: '28px',
  //   },
  // },
  // ctaBoxText2: {
  //   [theme.breakpoints.up('desktop')]: {
  //     fontSize: '12px',
  //   },
  // },
  contactUs: {
    [theme.breakpoints.up('tablet')]: {
      marginBottom: -150,
    },
    [theme.breakpoints.up('desktop')]: {
      marginBottom: 10,
    },
  },
}))

export default function Industries() {
  const classes = useStyles()
  return (
    <main className={classes.main}>
      <section className={classes.heroWrapper}>
        <div className={classes.hero}>
          <div className={classes.heroContent}>
            {/* <Divider style={{ marginBottom: 12 }} /> */}
            <Typography variant='h1' className={classes.heroTitle}>
              Laying the <br /> Foundation for All
              <br />
              <span style={{ color: 'rgba(47, 164, 59, 1)' }}>
                Digital Commerce
              </span>
            </Typography>

            <Typography variant='body1' className={classes.heroDescription}>
              If your organization operates online, Digital Identity matters to
              you. The ability to verify and authenticate users online is a
              fundamental necessity for doing business in the modern economy.
              {'\n\n'}
              CycurID&apos;s greatest advantage is our flexibility. By
              addressing foundational issues rather than building on outdated
              legacy systems, we provide better solutions for any company
              looking for a digital edge.
            </Typography>
            <div className={classes.buttonContainer}>
              <CTALink
                className={classes.signUpButton}
                href={process.env.GATSBY_DASHBOARD_SIGNUP_LINK}
                target='_blank'
                rel='noopener'
              >
                Get Started
              </CTALink>
              <CTALink
                className={classes.signUpButton}
                href={routes.CONTACT}
                variant='outline'
              >
                Talk To Sales
              </CTALink>
            </div>
          </div>
          <StaticImage
            className={classes.heroImage}
            src={HeroImage}
            alt='CycurID dashboard merchant signup form screenshot'
            loading='eager'
            quality={100}
          />
        </div>
      </section>

      <Section className={classes.fundamentalIssuesSection}>
        <div className={classes.fundamentalIssuesHead}>
          <Divider style={{ marginBottom: 20 }} />
          <SectionTitleBlock
            title='Same Issues, Different Sectors'
            description={
              <div className={classes.fundamentalIssuesText}>
                Even though the specific challenges your business faces are
                unique, CycurID addresses problems fundamental to the act of
                doing business digitally.
              </div>
            }
          />
        </div>
        <div className={classes.cycuridSolutionImage2}>
          <StaticImage
            src={IndustriesImg}
            alt='Grid of industries which can use the CycurID ID management solution, including financial services, retail/ecommerce, and healthcare'
            placeholder='tracedSVG'
            quality={100}
          />
        </div>
        <div className={classes.fundamentalIssuesFlexContainer}>
          <div className={classes.fundamentalIssuesItem}>
            <SecurityIcon />
            <Typography variant='h3'>Security</Typography>
            <Typography variant='body2'>
              Existing authentication methods, like passwords, are vulnerable to
              being breached.{'\n\n'}Authentication data is a security liability
              for digital businesses.{'\n\n'}Digital fraud and cybercrime are
              growing threats for businesses and consumers.
            </Typography>
          </div>
          <div className={classes.fundamentalIssuesItem}>
            <PrivacyIcon />
            <Typography variant='h3'>Privacy</Typography>
            <Typography variant='body2'>
              Last-gen authentication and verification methods require a
              customer to forfeit personal information.{'\n\n'}Private, personal
              information being online exposes businesses and consumers to
              cybercrime activity.{'\n\n'}
              New government regulations are aiming to reduce private
              information businesses can keep.
            </Typography>
          </div>
          <div className={classes.fundamentalIssuesItem}>
            <UsabilityIcon />
            <Typography variant='h3'>Usability</Typography>
            <Typography variant='body2'>
              Last-gen KYC/AML checks are time-consuming, expensive, and cause
              customer abandonment.{'\n\n'}Consumers expect digital user
              experiences to be efficient and expedient, requiring streamlined
              authentication and onboarding platforms.{'\n\n'}Best practices for
              digital security are not viable on last-gen platforms for
              businesses or your customers.
            </Typography>
          </div>
        </div>
      </Section>

      <Section className={classes.cycuridSolutionSection}>
        <StaticImage
          className={classes.cycuridSolutionImage}
          src={IndustriesImg}
          alt='Grid of industries which can use the CycurID ID management solution, including financial services, retail/ecommerce, and healthcare'
          placeholder='tracedSVG'
          quality={100}
        />
        <div>
          <div className={classes.cycuridSolutionCard}>
            <Typography variant='h2'>
              The Big <span style={{ color: '#2FA43B' }}>Problem</span>
            </Typography>
            <Typography variant='body1'>
              Existing solutions for verification, authentication and KYC/AML,
              simply don’t address the key issues facing modern digital
              organizations and so are simply not adequately serving your
              business’ need.
            </Typography>
          </div>
          <div className={classes.cycuridSolutionCard}>
            <Typography variant='h2'>
              The <span style={{ color: '#2FA43B' }}>CycurID</span> Solution
            </Typography>
            <Typography variant='body1'>
              All of these digital needs are connected by the same issue: you
              need to know who you’re doing business with, without seeing them
              face-to-face.{'\n\n'}By creating a comprehensive Digital Identity
              Management solution, CycurID enables your company to always know
              who you’re doing business with.
            </Typography>
          </div>
        </div>
      </Section>

      <Section className={classes.titleSection}>
        <Divider style={{ margin: 0 }} />
        <Typography variant='h2'>Your KYC PASSPORT</Typography>
        <br />
        <Typography
          variant='body1'
          className={classes.sectionDescription}
          style={{ color: 'white' }}
        >
          CycurID provides complete and comprehensive verification,
          authentication, KYC/AML, compliance, auditing, screening, KYT/KYA, and
          communication solutions for businesses.
        </Typography>
        {/* <Divider style={{ marginTop: 20 }} /> */}
      </Section>

      <CTABox
        title={
          // <p className={classes.ctaBoxText}>
          `No matter what industry you operate in, our Digital ID Managementplatform will help your business:`
          // </p>
        }
        description={
          // <p className={classes.ctaBoxText2}>
          `Our approach doesn't just give you the tools to amend the systems you already have. CycurID takes care of your core digital requirements for you, so you can focus on growing your business.`
          // </p>
        }
        className={classes.ctaBox}
        bar='true'
        // noShadow='True'
      />
      <div className={classes.contactUs}>
        <ContactUs />
      </div>
    </main>
  )
}
